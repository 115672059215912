<template>
  <ASmoothReflow class="m-osm-n" :class="{ 'm-osm-n--no-padding': hidePadding }">
    <div
      v-if="cartValidationMessage"
      class="m-osm-n__error"
      :class="{'m-osm-n__error--blink': blinkNotification}"
    >
      <span v-html="cartValidationMessage.message"></span>
    </div>
    <div
      v-if="cartValidationWarning && isWarningShow"
      class="m-osm-n__warning"
    >
      <span v-html="cartValidationWarning.message"></span>
    </div>
  </ASmoothReflow>
</template>
<script>
import ASmoothReflow from 'theme/components/atoms/a-smooth-reflow.vue';

export default {
  name: 'MSummaryNotification',
  components: { ASmoothReflow },
  props: {
    isWarningShow: {
      type: Boolean,
      default: () => true
    },
    cartValidationMessage: {
      type: [Object, null],
      default: () => null
    },
    cartValidationWarning: {
      type: [Object, null],
      default: () => null
    }
  },
  data () {
    return {
      blinkNotification: false,
      blinkNotificationTimeout: null
    }
  },
  computed: {
    hidePadding () {
      return !(this.cartValidationMessage || this.cartValidationWarning)
    }
  },
  methods: {
    blink () {
      this.blinkNotificationTimeout = setTimeout(() => {
        this.blinkNotification = true

        clearTimeout(this.blinkNotificationTimeout)

        this.blinkNotificationTimeout = setTimeout(() => {
          this.blinkNotification = false

          clearTimeout(this.blinkNotificationTimeout)
        }, 1000)
      }, 500)
    }
  },
  beforeDestroy () {
    clearTimeout(this.blinkNotificationTimeout)
  }
};
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
@import "~theme/css/mixins";
@import '~theme/css/fonts';

.m-osm-n {
  display: flex;
  flex-direction: column;
  gap: var(--spacer-15);
  font-size: var(--font-size-14);
  //font-weight: 600;
  transition: 200ms padding;
  opacity: 1;
  padding-top: var(--spacer-16);
  padding-bottom: var(--spacer-16);

  &--no-padding {
    padding: 0!important;
  }

  &__error {
    color: var(--red-main);

    &:before {
      background: var(--notification-error-background);
    }
  }

  &__warning {
    &:before {
      background: var(--yellow-corn-silk);
    }
  }

  &__error, &__warning {
    padding: var(--spacer-10);
    display: flex;
    gap: var(--spacer-10);
    position: relative;
    z-index: 0;

    &--blink {
      &:before {
        animation: notificationBlinkAnimation 1s linear;
      }
    }

    &:before {
      content: ' ';
      z-index: -1;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }

  @keyframes notificationBlinkAnimation {
    0% {
      opacity: 1;
    }

    25% {
      opacity: .6;
    }

    50% {
      opacity: 1;
    }

    75% {
      opacity: .6;
    }

    100% {
      opacity: 1;
    }
  }
}

</style>
